import React from "react";
import Text from "../sections/Text";
import ImgSklep2 from "../images/sklepy/sklep2.png";
import ImgSklep3 from "../images/sklepy/sklep3.png";
import ImgSklep4 from "../images/sklepy/sklep4.png";
import ImgSklep5 from "../images/sklepy/sklep5.png";
import ImgSklep6 from "../images/sklepy/sklep6.png";

const Shops = ({active = false}) => {
    return (<>

        <div id="sklepy" style={{display: active ? "block" : "none"}}>
            {/*<Text>*/}
            {/*    <br/>*/}
            {/*    <p className="text-big">*/}
            {/*        Nasze karmy znajdziesz w&nbsp;sklepach:*/}
            {/*        <br/>*/}
            {/*        <br/>*/}
            {/*    </p>*/}
            {/*</Text>*/}
            <p className="shops">
                <div>
                    <img src={ImgSklep2} alt="" width="200" height="200"/>
                </div>
                <div>
                    <img src={ImgSklep3} alt="" width="200" height="200"/>
                </div>
                <div>
                    <img src={ImgSklep4} alt="" width="200" height="200"/>
                </div>
                <div>
                    <img src={ImgSklep5} alt="" width="200" height="200"/>
                </div>
                <div>
                    <img src={ImgSklep6} alt="" width="200" height="200"/>
                </div>
            </p>
        </div>
    </>);
};

export default Shops;
