import React from "react";
import "./Hamburger.scss";

const Hamburger = ({active, clickHandle}) => {

  return(
    <div className={"hamburger" + (active ? " hamburger--open" : "")} onClick={clickHandle}>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
    </div>
  );
};

export default Hamburger;
