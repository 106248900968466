import React, {useState} from "react";
import Header from "../sections/Header";
import Screen from "../sections/Screen";

import Text from "../sections/Text";
import Gallery from "../sections/Gallery";

import Image01 from "../images/gallery/01.png"
import Image02 from "../images/gallery/02.png"
import Image03 from "../images/gallery/03.png"
import Image04 from "../images/gallery/04.png"
import ImgDog from "../images/karma/icons/ikona_PIES_2.svg"
import ImgDog2 from "../images/karma/icons/ikona_PIES.svg"
import Icon55Miesa from "../images/karma/ikona-55-miesa.png"
import IconWitaminaE from "../images/karma/ikona-witamina-e.png"
import IconWitaminaD3 from "../images/karma/ikona-witamina-d3.png"

import SucheKurczak from "../images/karma/pies/sucha/kurczak/EC_TULA_SUCHA_PIES_KURCZAK_JAGNIECINA_WIZ.png"
import SucheWolowina from "../images/karma/pies/sucha/wolowina/EC_TULA_SUCHA_PIES_WOLOWINA_WIZ.png"

import SaszetkiKurczak from "../images/karma/pies/saszetki/kurczak/EC_TULA_SASZETKI_PIES_KURCZAK_WIZ.png"
import SaszetkiWolowina from "../images/karma/pies/saszetki/wolowina/EC_TULA_SASZETKI_PIES_WOLOWINA_WIZ.png"

import FlowpackKurczak from "../images/karma/pies/flowpack/kurczak/EC_TULA_FLOWPACK_PIES_KURCZAK_WOLOWINA_WIZ.png";

import PasztetKurczak from "../images/karma/pies/pasztety/kurczak/EC_TULA_PIES_PASZTET_KURCZAK_WIZ.png";
import PasztetJagniecina from "../images/karma/pies/pasztety/jagniecina/EC_TULA_PIES_PASZTET_JAGNIECINA_WIZ.png";

import PrzekaskaHarder from "../images/karma/pies/przekaska/harder/EC_TULA_PIES_PRZEKASKA_HARDER_WIZ.png";
import PrzekaskaMieso from "../images/karma/pies/przekaska/mieso/EC_TULA_PIES_PRZEKASKA_MIESO_WIZ.png";


import Procent54 from "../images/karma/icons/54.svg"
import Procent55 from "../images/karma/icons/55.svg"
import Procent59 from "../images/karma/icons/59.svg"
import Procent61 from "../images/karma/icons/61.svg"
import Procent65 from "../images/karma/icons/65.svg"

import Procent74wFilecikach from "../images/karma/icons/74-w-filecikach.svg"
import Procent88wFilecikach from "../images/karma/icons/88-w-filecikach.svg"
import Procent82wKawalkach from "../images/karma/icons/82-w-kawalkach.svg"
import Inulina from "../images/karma/icons/INULINA.svg"
import Rokitnik from "../images/karma/icons/ROKITNIK.svg"
import WitaminaD3 from "../images/karma/icons/WIT.D3.svg"
import WitaminaE from "../images/karma/icons/WIT.E.svg"
import Naturalny from "../images/karma/icons/natural.svg"
import Proteinowy from "../images/karma/icons/protein.svg"

import Tabs from "../sections/Tabs";

const DogsProducts = () => {

    const [activeTab, setActiveTab] = useState(0);
    const [changeState, setChangeState] = useState(false);
    const [activeSlide, setActiveSlide] = useState([
        [false, true, true, true], // Karmy suche
        [false, true, true, true], // Saszetki
        [false, true, true, true], // Flowpacki
        [false, true, true, true], // Pasztety
        [false, true, true, true], // Przekąski
    ]);

    const products = [
        {
            image: Image01,
            alt: "Image01",
        },
        {
            image: Image02,
            alt: "Image02",
        },
        {
            image: Image03,
            alt: "Image03",
        },
        {
            image: Image04,
            alt: "Image04",
        },
    ];

    const productsSuche = [
        {
            image: SucheWolowina,
            alt: "TULA – KARMA PEŁNOPORCJOWA SUCHA DLA PSÓW DOROSŁYCH MAŁYCH I ŚREDNICH RAS Z WOŁOWINĄ I WARZYWAMI",
        },
        {
            image: SucheKurczak,
            alt: "TULA – KARMA PEŁNOPORCJOWA SUCHA DLA PSÓW DOROSŁYCH MAŁYCH I ŚREDNICH RAS Z KURCZAKIEM, JAGNIĘCINĄ I WARZYWAMI ",
        },
    ];

    const productsSaszetki = [
        {
            image: SaszetkiKurczak,
            alt: "TULA – KAWAŁKI Z KURCZAKIEM I WARZYWAMI W SOSIE DLA PSA",
        },
        {
            image: SaszetkiWolowina,
            alt: "TULA – KAWAŁKI Z WOŁOWINĄ I WARZYWAMI W SOSIE DLA PSA",
        },
    ];

    const productsFlowpack = [
        {
            image: FlowpackKurczak,
            alt: "TULA – KAWAŁKI DLA PSA: Z KURCZAKIEM I WARZYWAMI W SOSIE, Z WOŁOWINĄ I WARZYWAMI W SOSIE ",
        },
    ];

    const productsPasztety = [
        {
            image: PasztetKurczak,
            alt: "TULA – PASZTET Z KURCZAKIEM DLA PSA",
        },
        {
            image: PasztetJagniecina,
            alt: "TULA – PASZTET Z JAGNIĘCINĄ DLA PSA",
        },
    ];

    const productsPrzekaski = [
        {
            image: PrzekaskaHarder,
            alt: "TULA - PRZEKĄSKA HARDER WOŁOWY DLA PSA",
        },
        {
            image: PrzekaskaMieso,
            alt: "TULA – PRZEKĄSKA SUSZONE MIĘSO WOŁOWE DLA PSA",
        },
    ];

    const tabs = [
        'Karmy suche',
        'Saszetki',
        'Flowpacki',
        'Pasztety',
        'Przekąski',
    ];

    const onChangeSlideEvent = (slideNumber, sliderTab) => {

        const copyActiveSlideMatrix = activeSlide;

        copyActiveSlideMatrix[sliderTab].map((value, index) => {
            copyActiveSlideMatrix[sliderTab][index] = true;
        });

        copyActiveSlideMatrix[sliderTab][slideNumber] = false;

        setActiveSlide(copyActiveSlideMatrix);
        setChangeState(!changeState);
    };

    const toggleMoreDesc = (e) => {
        e.target.parentNode.querySelector('.js-toggle').classList.toggle('active');
        if(e.target.parentNode.querySelector('.js-toggle').classList.contains('active')) {
            e.target.innerHTML = e.target.dataset.activeLabel;
        } else {
            e.target.innerHTML = e.target.dataset.inactiveLabel;
        }
    };

    return (<>
        <Screen>
            <Header headerSize="2" title="Karmy dla psa"/>

            <Tabs setActiveTab={setActiveTab.bind(this)} activeTab={activeTab} tabs={tabs}>
                {/*Karma sucha*/}
                <div tabIndex={0} className="tab">
                    <div className="content">
                        <div className="slider-row">
                            <div>
                                <Gallery slides={productsSuche} onChange={(sliderNumber) => {onChangeSlideEvent(sliderNumber, 0)}}/>
                            </div>
                            <div>
                                <Text hidden={activeSlide[0][0]}>
                                    <Header headerSize="3" title="Sucha"/>
                                    <p>
                                        <img src={ImgDog2} width="90" height="70"/>
                                    </p>
                                    <p className="text-big">
                                        TULA – KARMA PEŁNOPORCJOWA SUCHA DLA PSÓW DOROSŁYCH MAŁYCH I&nbsp;ŚREDNICH RAS Z&nbsp;WOŁOWINĄ I&nbsp;WARZYWAMI
                                        <br/>
                                        <br/>
                                    </p>
                                    <div className="icons-row">
                                        <div>
                                            <img src={Procent55} width="76" height="76" alt="55% mięsa"/>
                                        </div>
                                        <div>
                                            <img src={WitaminaD3} width="76" height="76" alt="Witamina D3"/>
                                        </div>
                                        <div>
                                            <img src={WitaminaE} width="76" height="76" alt="Witamina E"/>
                                        </div>
                                    </div>
                                    <p>
                                        Karma zawiera 55%&nbsp;mięsa i&nbsp;produktów pochodzenia zwierzęcego. Zawiera również witaminę&nbsp;D<sub>3</sub> dla mocnych kości i&nbsp;zdrowych zębów oraz witaminę&nbsp;E wspierającą układ odpornościowy psa. W&nbsp;składzie karmy znajdują się również warzywa i&nbsp;owoce takie jak: marchew suszona, brokuły suszone, pomidory suszone, szpinak suszony, żurawina suszona i&nbsp;jagody suszone. Sprawdza się w&nbsp;codziennym żywieniu psów.
                                        <br/>
                                        <br/>
                                        Masa netto: 300g
                                    </p>

                                    <a data-inactive-label="Pokaż pełen skład" data-active-label="Ukryj pełen skład" onClick={toggleMoreDesc}>Pokaż pełen skład</a>

                                    <p className="toggle js-toggle">
                                        Skład: wołowina suszona (28%), wołowina świeża (23%), tłuszcz z&nbsp;kurczaka (10%), groch, groch obłuszczony, białko zwierzęce z&nbsp;wątroby kurczaka (hydrolizowane) (4%), płatki ziemniaczane, marchew suszona (4%), wysłodki buraczane suszone (z&nbsp;buraków cukrowych), składniki mineralne, cykoria suszona (1%), siemię lniane (1%), olej z&nbsp;łososia (0,5%), drożdże piwne suszone (0,5%), brokuły suszone (0,15%), pomidory suszone (0,15%),  szpinak suszony (0,15%), glukozamina, siarczan chondroityny, żurawina suszona (0,15%), jagody suszone (0,15%), ekstrakty roślinne (Yucca Mojave, Rosmarinus sp., Vitis sp., Curcuma sp., Citrus sp., Eugenia sp.).
                                    </p>
                                </Text>
                                <Text hidden={activeSlide[0][1]}>
                                    <Header headerSize="3" title="Sucha"/>
                                    <p>
                                        <img src={ImgDog2} width="90" height="70"/>
                                    </p>
                                    <p className="text-big">
                                        TULA – KARMA PEŁNOPORCJOWA SUCHA DLA PSÓW DOROSŁYCH MAŁYCH I&nbsp;ŚREDNICH RAS Z&nbsp;KURCZAKIEM, JAGNIĘCINĄ I&nbsp;WARZYWAMI
                                        <br/>
                                        <br/>
                                    </p>
                                    <div className="icons-row">
                                        <div>
                                            <img src={Procent54} width="76" height="76" alt="54% mięsa"/>
                                        </div>
                                        <div>
                                            <img src={WitaminaD3} width="76" height="76" alt="Witamina D3"/>
                                        </div>
                                        <div>
                                            <img src={WitaminaE} width="76" height="76" alt="Witamina E"/>
                                        </div>
                                    </div>
                                    <p>
                                        Karma zawiera 54%&nbsp;mięsa i&nbsp;produktów pochodzenia zwierzęcego. Zawiera również witaminę&nbsp;D<sub>3</sub> dla mocnych kości i&nbsp;zdrowych zębów oraz witaminę&nbsp;E wspierającą układ odpornościowy psa. W&nbsp;składzie karmy można znaleźć warzywa i&nbsp;owoce takie jak: brokuły suszone, pomidory suszone, marchew suszona, szpinak suszony, żurawina suszona i&nbsp;jagody suszone. Sprawdza się w&nbsp;codziennym żywieniu psów.
                                        <br/>
                                        <br/>
                                        Masa netto: 300g
                                    </p>

                                    <a data-inactive-label="Pokaż pełen skład" data-active-label="Ukryj pełen skład" onClick={toggleMoreDesc}>Pokaż pełen skład</a>

                                    <p className="toggle js-toggle">
                                        Skład: kurczak świeży (25%), kurczak suszony (21%), groch, groch obłuszczony, tłuszcz z&nbsp;kurczaka (9%), białko zwierzęce z&nbsp;wątroby kurczaka (hydrolizowane) (4%), płatki ziemniaczane, jagnięcina suszona (4%), białko ziemniaczane, marchew suszona (4%), wysłodki buraczane suszone (z&nbsp;buraków cukrowych), składniki mineralne, cykoria suszona (1%), siemię lniane (1%), olej z&nbsp;łososia (0,5%), drożdże piwne suszone (0,5%), brokuły suszone (0,15%), pomidory suszone (0,15%),  szpinak suszony (0,15%), glukozamina, siarczan chondroityny, żurawina suszona (0,15%), jagody suszone (0,15%), ekstrakty roślinne (Yucca Mojave, Rosmarinus sp., Vitis sp., Curcuma sp., Citrus sp., Eugenia sp.).
                                    </p>
                                </Text>
                            </div>
                        </div>
                    </div>
                </div>
                {/*Saszetki*/}
                <div tabIndex={1} className="tab">
                    <div className="content">
                        <div className="slider-row">
                            <div>
                                <Gallery slides={productsSaszetki} onChange={(sliderNumber) => {onChangeSlideEvent(sliderNumber, 1)}}/>
                            </div>
                            <div>
                                <Text hidden={activeSlide[1][0]}>
                                    <Header headerSize="3" title="Saszetki"/>
                                    <p>
                                        <img src={ImgDog} width="90" height="70"/>
                                    </p>
                                    <p className="text-big">
                                        TULA – KAWAŁKI Z&nbsp;KURCZAKIEM I&nbsp;WARZYWAMI W&nbsp;SOSIE DLA&nbsp;PSA
                                        <br/>
                                        <br/>
                                    </p>
                                    <div className="icons-row">
                                        <div>
                                            <img src={Procent82wKawalkach} width="76" height="76" alt="82% mięsa w kawałkach"/>
                                        </div>
                                        <div>
                                            <img src={Inulina} width="76" height="76" alt="Inulina"/>
                                        </div>
                                    </div>
                                    <p>
                                        Karma zawiera 82%&nbsp;mięsa i&nbsp;produktów pochodzenia zwierzęcego w&nbsp;kawałku*.  W&nbsp;składzie karmy znajdują się między innymi warzywa – zielony groszek i&nbsp;marchewka, a&nbsp;także niezbędne oleje oraz tłuszcze. Dla wspomagania prawidłowego funkcjonowania układu pokarmowego psa karma ta zawiera inulinę. Co ważne karma nie zawiera substancji konserwujących i&nbsp;barwników. Sprawdza się w&nbsp;codziennym żywieniu psa.
                                        <br/><br/>
                                        <b>*kawałki standardowo stanowią 44%&nbsp;produktu</b>
                                        <br/>
                                        <br/>
                                        Masa netto: 100g
                                    </p>

                                    <a data-inactive-label="Pokaż pełen skład" data-active-label="Ukryj pełen skład" onClick={toggleMoreDesc}>Pokaż pełen skład</a>

                                    <p className="toggle js-toggle">
                                        Skład: mięso i&nbsp;produkty pochodzenia zwierzęcego (82%&nbsp;w&nbsp;kawałkach*, w&nbsp;tym 9%&nbsp;kurczaka i&nbsp;9%&nbsp;wątroby wieprzowej), zboża, warzywa (4%&nbsp;zielonego groszku i&nbsp;marchewki), oleje i&nbsp;tłuszcze (0,5%&nbsp;oleju słonecznikowego), roślinne ekstrakty białkowe, produkty pochodzenia roślinnego (0,4%&nbsp;inuliny), substancje mineralne, cukry.
                                    </p>
                                </Text>
                                <Text hidden={activeSlide[1][1]}>
                                    <Header headerSize="3" title="Saszetki"/>
                                    <p>
                                        <img src={ImgDog} width="90" height="70"/>
                                    </p>
                                    <p className="text-big">
                                        TULA – KAWAŁKI Z&nbsp;WOŁOWINĄ I&nbsp;WARZYWAMI W&nbsp;SOSIE DLA&nbsp;PSA
                                        <br/>
                                        <br/>
                                    </p>
                                    <div className="icons-row">
                                        <div>
                                            <img src={Procent82wKawalkach} width="76" height="76" alt="82% mięsa w kawałkach"/>
                                        </div>
                                        <div>
                                            <img src={Inulina} width="76" height="76" alt="Inulina"/>
                                        </div>
                                    </div>
                                    <p>
                                        Karma zawiera 82%&nbsp;mięsa i&nbsp;produktów pochodzenia zwierzęcego w&nbsp;kawałku*. W&nbsp;karmie znajdują się również między innymi warzywa – zielony groszek i&nbsp;marchewka, a&nbsp;także niezbędne oleje oraz tłuszcze. Dla wspomagania prawidłowego funkcjonowania układu pokarmowego psa karma ta zawiera inulinę. Co ważne karma nie zawiera substancji konserwujących i&nbsp;barwników. Sprawdza się w&nbsp;codziennym żywieniu psa.
                                        <br/><br/>
                                        <b>*kawałki standardowo stanowią 44%&nbsp;produktu</b>
                                        <br/>
                                        <br/>
                                        Masa netto: 100g
                                    </p>

                                    <a data-inactive-label="Pokaż pełen skład" data-active-label="Ukryj pełen skład" onClick={toggleMoreDesc}>Pokaż pełen skład</a>

                                    <p className="toggle js-toggle">
                                        Skład: mięso i&nbsp;produkty pochodzenia zwierzęcego (82%&nbsp;w&nbsp;kawałkach*, w&nbsp;tym 9%&nbsp;wołowiny i&nbsp;9%&nbsp;wątroby wieprzowej), zboża, warzywa (4%&nbsp;zielonego groszku i&nbsp;marchewki), oleje i&nbsp;tłuszcze (0,5%&nbsp;oleju słonecznikowego), roślinne ekstrakty białkowe, produkty pochodzenia roślinnego (0,4%&nbsp;inuliny), substancje mineralne, cukry.
                                    </p>
                                </Text>
                            </div>
                        </div>
                    </div>
                </div>
                {/*Flowpack*/}
                <div tabIndex={2} className="tab">
                    <div className="content">
                        <div className="slider-row">
                            <div>
                                <Gallery slides={productsFlowpack} onChange={(sliderNumber) => {onChangeSlideEvent(sliderNumber, 2)}}/>
                            </div>
                            <div>
                                <Text hidden={activeSlide[2][0]}>
                                    <Header headerSize="3" title="Saszetki"/>
                                    <p>
                                        <img src={ImgDog} width="90" height="70"/>
                                    </p>
                                    <p className="text-big">
                                        TULA – KAWAŁKI DLA PSA: Z&nbsp;KURCZAKIEM I&nbsp;WARZYWAMI W&nbsp;SOSIE, Z&nbsp;WOŁOWINĄ I&nbsp;WARZYWAMI W&nbsp;SOSIE
                                        <br/>
                                        <br/>
                                    </p>
                                    <div className="icons-row">
                                        <div>
                                            <img src={Procent82wKawalkach} width="76" height="76" alt="82% mięsa w kawałkach"/>
                                        </div>
                                        <div>
                                            <img src={Inulina} width="76" height="76" alt="Inulina"/>
                                        </div>
                                    </div>
                                    <p>
                                        Karmy zawierają 82%&nbsp;mięsa i&nbsp;produktów pochodzenia zwierzęcego w&nbsp;kawałku*. W&nbsp;składach karm znajdują się również między innymi warzywa – zielony groszek i&nbsp;marchewka, niezbędne oleje – olej słonecznikowy. Dla wspomagania prawidłowego funkcjonowania układu pokarmowego psa karma ta zawiera inulinę.  Co ważne karma nie zawiera substancji konserwujących i&nbsp;barwników. Sprawdza się w&nbsp;codziennym żywieniu psa.
                                        <br/><br/>
                                        <b>*kawałki standardowo stanowią 44%&nbsp;produktu</b>
                                        <br/>
                                        <br/>
                                        Masa netto: 4x100g
                                    </p>

                                    <a data-inactive-label="Pokaż pełen skład" data-active-label="Ukryj pełen skład" onClick={toggleMoreDesc}>Pokaż pełen skład</a>

                                    <p className="toggle js-toggle">
                                        Saszetka z&nbsp;kurczakiem skład:<br/><br/>
                                        mięso i&nbsp;produkty pochodzenia zwierzęcego (82%&nbsp;w&nbsp;kawałkach*, w&nbsp;tym 9%&nbsp;kurczaka i&nbsp;9%&nbsp;wątroby wieprzowej), zboża, warzywa (4%&nbsp;zielonego groszku i&nbsp;marchewki), oleje i&nbsp;tłuszcze (0,5%&nbsp;oleju słonecznikowego), roślinne ekstrakty białkowe, produkty pochodzenia roślinnego (0,4%&nbsp;inuliny), substancje mineralne, cukry.
                                        <br/><br/>
                                        Saszetka z&nbsp;wołowiną skład:<br/><br/>
                                        mięso i&nbsp;produkty pochodzenia zwierzęcego (82%&nbsp;w&nbsp;kawałkach*, w&nbsp;tym 9%&nbsp;wołowiny i&nbsp;9%&nbsp;wątroby wieprzowej), zboża, warzywa (4%&nbsp;zielonego groszku i&nbsp;marchewki), oleje i&nbsp;tłuszcze (0,5%&nbsp;oleju słonecznikowego), roślinne ekstrakty białkowe, produkty pochodzenia roślinnego (0,4%&nbsp;inuliny), substancje mineralne, cukry.
                                    </p>
                                </Text>
                            </div>
                        </div>
                    </div>
                </div>
                {/*Pasztet*/}
                <div tabIndex={3} className="tab">
                    <div className="content">
                        <div className="slider-row">
                            <div>
                                <Gallery slides={productsPasztety} onChange={(sliderNumber) => {onChangeSlideEvent(sliderNumber, 3)}}/>
                            </div>
                            <div>
                                <Text hidden={activeSlide[3][0]}>
                                    <Header headerSize="3" title="Pasztet"/>
                                    <p>
                                        <img src={ImgDog} width="90" height="70"/>
                                    </p>
                                    <p className="text-big">
                                        TULA – PASZTET Z&nbsp;KURCZAKIEM DLA&nbsp;PSA
                                        <br/>
                                        <br/>
                                    </p>
                                    <div className="icons-row">
                                        <div>
                                            <img src={Procent59} width="76" height="76" alt="59% mięsa"/>
                                        </div>
                                        <div>
                                            <img src={WitaminaD3} width="76" height="76" alt="Witamina D3"/>
                                        </div>
                                        <div>
                                            <img src={WitaminaE} width="76" height="76" alt="Witamina E"/>
                                        </div>
                                    </div>
                                    <p>
                                        Karma zawiera 59%&nbsp;mięsa i&nbsp;produktów pochodzenia zwierzęcego. Oprócz tego w&nbsp;składzie znajduje się również witamina&nbsp;D<sub>3</sub> dla mocnych kości i&nbsp;zdrowych zębów oraz witamina&nbsp;E, która wspiera układ odpornościowy zwierzaka. Pasztet nie zawiera dodatku aromatów. Sprawdza się w&nbsp;codziennym żywieniu psa.
                                        <br/>
                                        <br/>
                                        Masa netto: 400g
                                    </p>

                                    <a data-inactive-label="Pokaż pełen skład" data-active-label="Ukryj pełen skład" onClick={toggleMoreDesc}>Pokaż pełen skład</a>

                                    <p className="toggle js-toggle">
                                        59%&nbsp;mięso i&nbsp;produkty pochodzenia zwierzęcego (w&nbsp;tym 30%&nbsp;kurczaka i&nbsp;4%&nbsp;wątroby wieprzowej), ryby i&nbsp;produkty rybne, substancje mineralne, produkty pochodzenia roślinnego (0,4%&nbsp;inuliny).
                                    </p>
                                </Text>
                                <Text hidden={activeSlide[3][1]}>
                                    <Header headerSize="3" title="Pasztet"/>
                                    <p>
                                        <img src={ImgDog} width="90" height="70"/>
                                    </p>
                                    <p className="text-big">
                                        TULA – PASZTET Z&nbsp;JAGNIĘCINĄ DLA&nbsp;PSA
                                        <br/>
                                        <br/>
                                    </p>
                                    <div className="icons-row">
                                        <div>
                                            <img src={Procent61} width="76" height="76" alt="61% mięsa"/>
                                        </div>
                                        <div>
                                            <img src={WitaminaD3} width="76" height="76" alt="Witamina D3"/>
                                        </div>
                                        <div>
                                            <img src={WitaminaE} width="76" height="76" alt="Witamina E"/>
                                        </div>
                                    </div>
                                    <p>
                                        Karma zawiera 61%&nbsp;mięsa i&nbsp;produktów pochodzenia zwierzęcego. Oprócz tego w&nbsp;składzie znajduje się również witamina&nbsp;D<sub>3</sub> dla mocnych kości i&nbsp;zdrowych zębów oraz witamina&nbsp;E, która wspiera układ odpornościowy zwierzaka. Pasztet nie zawiera dodatku aromatów. Sprawdza się w&nbsp;codziennym żywieniu psa.
                                        <br/>
                                        <br/>
                                        Masa netto: 400g
                                    </p>

                                    <a data-inactive-label="Pokaż pełen skład" data-active-label="Ukryj pełen skład" onClick={toggleMoreDesc}>Pokaż pełen skład</a>

                                    <p className="toggle js-toggle">
                                        Skład: 61%&nbsp;mięso i&nbsp;produkty pochodzenia zwierzęcego (w&nbsp;tym 30%&nbsp;jagnięciny i&nbsp;4%&nbsp;wątroby wieprzowej), ryby i&nbsp;produkty rybne, substancje mineralne, produkty pochodzenia roślinnego (0,4%&nbsp;inuliny).
                                    </p>
                                </Text>
                            </div>
                        </div>
                    </div>
                </div>
                {/*Przekąski*/}
                <div tabIndex={4} className="tab">
                    <div className="content">
                        <div className="slider-row">
                            <div>
                                <Gallery slides={productsPrzekaski} onChange={(sliderNumber) => {onChangeSlideEvent(sliderNumber, 4)}}/>
                            </div>
                            <div>
                                <Text hidden={activeSlide[4][0]}>
                                    <Header headerSize="3" title="Przekąski"/>
                                    <p>
                                        <img src={ImgDog} width="90" height="70"/>
                                    </p>
                                    <p className="text-big">
                                        Harder Wołowy
                                        <br/>
                                        <br/>
                                    </p>
                                    <div className="icons-row">
                                        <div>
                                            <img src={Proteinowy} width="76" height="76" alt="Monoproteinowy"/>
                                        </div>
                                    </div>
                                    <p>
                                        Karma zawiera 93%&nbsp;mięsa i&nbsp;produktów pochodzenia zwierzęcego. Harder to&nbsp;produkt monoproteinowy, czyli w&nbsp;swoim składzie posiada tylko jedno źródło białka, przez co&nbsp;jest idealnym rozwiązaniem dla&nbsp;psów, które borykają się z&nbsp;problemem nietolerancji pokarmowych, stanowi znakomite uzupełnienie codziennej diety Twojego psa. Oprócz tego w składzie znajdują się&nbsp;również produkty pochodzenia roślinnego. Sprawdzi się doskonale jako nagroda lub&nbsp;przekąska pomiędzy posiłkami.
                                        <br/>
                                        <br/>
                                        Masa netto: 50g
                                    </p>
                                    <a data-inactive-label="Pokaż pełen skład" data-active-label="Ukryj pełen skład" onClick={toggleMoreDesc}>Pokaż pełen skład</a>

                                    <p className="toggle js-toggle">
                                        Skład: mięso i&nbsp;produkty pochodzenia zwierzęcego (wołowina 93&nbsp;%), produkty pochodzenia roślinnego, minerały. Dodatki: konserwanty, przeciwutleniacz, barwnik.
                                    </p>
                                </Text>
                                <Text hidden={activeSlide[4][1]}>
                                    <Header headerSize="3" title="Przekąski"/>
                                    <p>
                                        <img src={ImgDog} width="90" height="70"/>
                                    </p>
                                    <p className="text-big">
                                        Suszone mięso wołowe
                                        <br/>
                                        <br/>
                                    </p>
                                    <div className="icons-row">
                                        <div>
                                            <img src={Naturalny} width="76" height="76" alt="Naturalny"/>
                                        </div>
                                    </div>
                                    <p>
                                        Karma zawiera 100%&nbsp;mięsa wołowego. Produkt jest w&nbsp;100% naturalny. Idealnie odpowiada na&nbsp;potrzebę gryzienia czworonoga. Stanowi znakomite uzupełnienie codziennej diety Twojego psa, dzięki wysokiej zawartości białka. Przekąska posiada cynk, który wpływa na&nbsp;zdrowie sierści i&nbsp;skóry. Suszenie w&nbsp;niskich temperaturach sprawia, że&nbsp;zostają zachowane naturalnie występujące w&nbsp;mięsie witaminy, a&nbsp;białko jest pełnowartościowe. Sprawdzi się&nbsp;doskonale jako nagroda lub&nbsp;przekąska pomiędzy posiłkami.
                                        <br/>
                                        <br/>
                                        Masa netto: 30g
                                    </p>

                                    <a data-inactive-label="Pokaż pełen skład" data-active-label="Ukryj pełen skład" onClick={toggleMoreDesc}>Pokaż pełen skład</a>
                                    <p className="toggle js-toggle">
                                        Skład: suszony przełyk wołowy.
                                    </p>
                                </Text>
                            </div>
                        </div>
                    </div>
                </div>
            </Tabs>
        </Screen>
    </>);
};

export default DogsProducts;
