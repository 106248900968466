import React from "react";
import "./Text.scss";

const Text = ({children, hidden = false}) => {
  return(<div className={`text${hidden ? ' text--hidden' : ''}`}>
    {children}
  </div>);
};

export default Text;
