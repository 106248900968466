import React, {useState} from "react";
import Header from "../sections/Header";
import Screen from "../sections/Screen";

import Text from "../sections/Text";
import Gallery from "../sections/Gallery";

import Image01 from "../images/gallery/01.png"
import Image02 from "../images/gallery/02.png"
import Image03 from "../images/gallery/03.png"
import Image04 from "../images/gallery/04.png"
import ImgCat from "../images/karma/icons/ikona_KOT.svg";
import Icon61Miesa from "../images/karma/ikona-61-miesa.png"
import IconWitaminaE from "../images/karma/ikona-witamina-e.png"
import IconWitaminaD3 from "../images/karma/ikona-witamina-d3.png"
import Tabs from "../sections/Tabs";
import Icon55Miesa from "../images/karma/ikona-55-miesa.png";

import FlowpackIndyk from "../images/karma/kot/flowpack/indyk/EC_TULA_FLOWPACK_KOT_LOSOS_INDYK_WIZ.png";
import FlowpackKurczak from "../images/karma/kot/flowpack/kurczak/EC_TULA_FLOWPACK_KOT_KURCZAK_WOLOWINA_WIZ.png";
import PuszkaKurczak from "../images/karma/kot/puszka/EC_TULA_KOT_MUS_KURCZAK_WIZ.png";
import PuszkaWolowina from "../images/karma/kot/puszka/EC_TULA_KOT_MUS_WOLOWINA_WIZ.png";

import SaszetkaKurczak from "../images/karma/kot/saszetki/kurczak/EC_TULA_SASZETKI_KOT_KURCZAK_WIZ.png";
import SaszetkaIndyk from "../images/karma/kot/saszetki/indyk/EC_TULA_SASZETKI_KOT_INDYK_WIZ.png";
import SaszetkaLosos from "../images/karma/kot/saszetki/losos/EC_TULA_SASZETKI_KOT_LOSOS_WIZ.png";
import SaszetkaWolowina from "../images/karma/kot/saszetki/wolowina/EC_TULA_SASZETKI_KOT_WOLOWINA_WIZ.png";

import SucheKurczak from "../images/karma/kot/suche/kurczak/EC_TULA_SUCHA_KOT_KURCZAK_WIZ.png";
import SucheWolowina from "../images/karma/kot/suche/wolowina/EC_TULA_SUCHA_KOT_WOLOWINA_WIZ.png";

import Procent54 from "../images/karma/icons/54.svg"
import Procent55 from "../images/karma/icons/55.svg"
import Procent59 from "../images/karma/icons/59.svg"
import Procent61 from "../images/karma/icons/61.svg"
import Procent65 from "../images/karma/icons/65.svg"

import Procent74wFilecikach from "../images/karma/icons/74-w-filecikach.svg"
import Procent88wFilecikach from "../images/karma/icons/88-w-filecikach.svg"
import Procent82wKawalkach from "../images/karma/icons/82-w-kawalkach.svg"
import Inulina from "../images/karma/icons/INULINA.svg"
import Rokitnik from "../images/karma/icons/ROKITNIK.svg"
import WitaminaD3 from "../images/karma/icons/WIT.D3.svg"
import WitaminaE from "../images/karma/icons/WIT.E.svg"

const CatsProducts = () => {

    const [activeTab, setActiveTab] = useState(0);
    const [changeState, setChangeState] = useState(false);
    const [activeSlide, setActiveSlide] = useState([
        [false, true, true, true], // Karmy suche
        [false, true, true, true], // Saszetki
        [false, true, true, true], // Flowpacki
        [false, true, true, true], // Pasztety
    ]);

    const products = [
        {
            image: Image01,
            alt: "Image01",
        },
        {
            image: Image02,
            alt: "Image02",
        },
        {
            image: Image03,
            alt: "Image03",
        },
        {
            image: Image04,
            alt: "Image04",
        },
    ];

    const productsSuche = [
        {
            image: SucheKurczak,
            alt: "TULA – KARMA PEŁNOPORCJOWA SUCHA DLA KOTÓW DOROSŁYCH Z KURCZAKIEM I WARZYWAMI",
        },
        {
            image: SucheWolowina,
            alt: "TULA – KARMA PEŁNOPORCJOWA SUCHA DLA KOTÓW DOROSŁYCH Z WOŁOWINĄ I WARZYWAMI ",
        }
    ];

    const productsSaszetki = [
        {
            image: SaszetkaKurczak,
            alt: "TULA – FILECIKI Z KURCZAKIEM  W SOSIE DLA KOTA",
        },
        {
            image: SaszetkaWolowina,
            alt: "TULA – FILECIKI Z WOŁOWINĄ W SOSIE DLA KOTA",
        },
        {
            image: SaszetkaIndyk,
            alt: "TULA – FILECIKI Z INDYKIEM W SOSIE DLA KOTA",
        },
        {
            image: SaszetkaLosos,
            alt: "TULA – FILECIKI Z ŁOSOSIEM W SOSIE DLA KOTA",
        },
    ];

    const productsFlowpack = [
        {
            image: FlowpackKurczak,
            alt: "TULA – FILECIKI DLA KOTA: Z KURCZAKIEM W SOSIE, Z WOŁOWINĄ W SOSIE",
        },
        {
            image: FlowpackIndyk,
            alt: "TULA – FILECIKI DLA KOTA: Z INDYKIEM W SOSIE, Z ŁOSOSIEM  W SOSIE ",
        },
    ];

    const tabs = [
        'Karmy suche',
        'Saszetki',
        'Flowpacki',
        // 'Pasztety'
    ];

    const onChangeSlideEvent = (slideNumber, sliderTab) => {

        const copyActiveSlideMatrix = activeSlide;

        copyActiveSlideMatrix[sliderTab].map((value, index) => {
            copyActiveSlideMatrix[sliderTab][index] = true;
        });

        copyActiveSlideMatrix[sliderTab][slideNumber] = false;

        setActiveSlide(copyActiveSlideMatrix);
        setChangeState(!changeState);
    };

    const toggleMoreDesc = (e) => {
        e.target.parentNode.querySelector('.js-toggle').classList.toggle('active');
        if(e.target.parentNode.querySelector('.js-toggle').classList.contains('active')) {
            e.target.innerHTML = e.target.dataset.activeLabel;
        } else {
            e.target.innerHTML = e.target.dataset.inactiveLabel;
        }
    };

    return (<>
        <Screen>
            <Header headerSize="2" title="Karmy dla kota"/>

            <Tabs setActiveTab={setActiveTab.bind(this)} activeTab={activeTab} tabs={tabs}>
                {/*Sucha*/}
                <div tabIndex={0} className="tab">
                    <div className="content">
                        <div className="slider-row">
                            <div>
                                <Gallery slides={productsSuche} onChange={(sliderNumber) => {onChangeSlideEvent(sliderNumber, 0)}}/>
                            </div>
                            <div>
                                <Text hidden={activeSlide[0][0]}>
                                    <Header headerSize="3" title="Karma sucha"/>
                                    <p>
                                        <img src={ImgCat} width="90" height="70"/>
                                    </p>
                                    <p className="text-big">
                                        TULA – KARMA PEŁNOPORCJOWA SUCHA DLA KOTÓW DOROSŁYCH Z&nbsp;KURCZAKIEM I&nbsp;WARZYWAMI
                                        <br/>
                                        <br/>
                                    </p>
                                    <div className="icons-row">
                                        <div>
                                            <img src={Procent65} width="76" height="76" alt="65% mięsa"/>
                                        </div>
                                        <div>
                                            <img src={WitaminaD3} width="76" height="76" alt="Witamina D3"/>
                                        </div>
                                        <div>
                                            <img src={WitaminaE} width="76" height="76" alt="Witamina E"/>
                                        </div>
                                    </div>
                                    <p>
                                        Karma zawiera 65% mięsa i&nbsp;produktów pochodzenia zwierzęcego. Karma ta nie zawiera dodatku barwników oraz zbóż. Zawiera również witaminę&nbsp;D<sub>3</sub> dla mocnych kości i&nbsp;zdrowych zębów oraz witaminę&nbsp;E, która wspomaga układ odpornościowy kota. W&nbsp;składzie karmy można znaleźć między innymi warzywa i&nbsp;owoce takie jak: brokuły suszone, pomidory suszone, szpinak suszony, żurawina suszona i&nbsp;jagody suszone. Sprawdza się w&nbsp;codziennym żywieniu kotów.
                                        <br/>
                                        <br/>
                                        Masa netto: 300g
                                    </p>
                                    <a data-inactive-label="Pokaż pełen skład" data-active-label="Ukryj pełen skład" onClick={toggleMoreDesc}>Pokaż pełen skład</a>

                                    <p className="toggle js-toggle">
                                        Skład: kurczak suszony (33%), kurczak świeży (25%), płatki ziemniaczane, groch (8,5%), groch obłuszczony, tłuszcz z&nbsp;kurczaka (5%), białko zwierzęce z&nbsp;kurczaka (hydrolizowane) (5%), białko zwierzęce z&nbsp;wątroby kurczaka (hydrolizowane) (2%), wysłodki buraczane suszone (z&nbsp;buraków cukrowych), składniki mineralne, cykoria suszona (0,5%), drożdże piwne suszone (0,5%), olej z&nbsp;łososia (0,5%), lignoceluloza (ze świerku), brokuły suszone (0,15%), pomidory suszone (0,15%),  szpinak suszony (0,15%), glukozamina, siarczan chondroityny, żurawina suszona (0,15%), jagody suszone (0,15%), ekstrakty roślinne (Yucca Mojave, Rosmarinus sp., Vitis sp., Curcuma sp., Citrus sp., Eugenia sp.)
                                    </p>
                                </Text>
                                <Text hidden={activeSlide[0][1]}>
                                    <Header headerSize="3" title="Karma sucha"/>
                                    <p>
                                        <img src={ImgCat} width="90" height="70"/>
                                    </p>
                                    <p className="text-big">
                                        TULA – KARMA PEŁNOPORCJOWA SUCHA DLA KOTÓW DOROSŁYCH Z&nbsp;WOŁOWINĄ I&nbsp;WARZYWAMI
                                        <br/>
                                        <br/>
                                    </p>
                                    <div className="icons-row">
                                        <div>
                                            <img src={Procent61} width="76" height="76" alt="61% mięsa"/>
                                        </div>
                                        <div>
                                            <img src={WitaminaD3} width="76" height="76" alt="Witamina D3"/>
                                        </div>
                                        <div>
                                            <img src={WitaminaE} width="76" height="76" alt="Witamina E"/>
                                        </div>
                                    </div>
                                    <p>
                                        Karma zawiera 61% mięsa i&nbsp;produktów pochodzenia zwierzęcego. Karma ta nie zawiera dodatku barwników oraz zbóż. Zawiera również witaminę&nbsp;D<sub>3</sub> dla mocnych kości i&nbsp;zdrowych zębów oraz witaminę&nbsp;E, która wspomaga układ odpornościowy kota. W&nbsp;składzie karmy można znaleźć między innymi warzywa i&nbsp;owoce takie jak: brokuły suszone, pomidory suszone, szpinak suszony, żurawina suszona i&nbsp;jagody suszone. Sprawdza się w&nbsp;codziennym żywieniu kotów.
                                        <br/>
                                        <br/>
                                        Masa netto: 300g
                                    </p>

                                    <a data-inactive-label="Pokaż pełen skład" data-active-label="Ukryj pełen skład" onClick={toggleMoreDesc}>Pokaż pełen skład</a>

                                    <p className="toggle js-toggle">
                                        Skład:  wołowina suszona (31%), wołowina świeża (23%), płatki ziemniaczane, groch (7,5%), groch obłuszczony, tłuszcz z&nbsp;kurczaka (7%), białko zwierzęce z&nbsp;kurczaka (hydrolizowane) (5%), drożdże piwne suszone (3%), białko zwierzęce z&nbsp;wątroby kurczaka (hydrolizowane) (2%), składniki mineralne, wysłodki buraczane suszone (z&nbsp;buraków cukrowych), cykoria suszona (0,5%), olej z&nbsp;łososia (0,5%), lignoceluloza (ze świerku), brokuły suszone (0,15%), pomidory suszone (0,15%),  szpinak suszony (0,15%), glukozamina, siarczan chondroityny, żurawina suszona (0,15%), jagody suszone (0,15%), ekstrakty roślinne (Yucca Mojave, Rosmarinus sp., Vitis sp., Curcuma sp., Citrus sp., Eugenia sp.).
                                    </p>
                                </Text>
                            </div>
                        </div>
                    </div>
                </div>
                {/*Saszetki*/}
                <div tabIndex={1} className="tab">
                    <div className="content">
                        <div className="slider-row">
                            <div>
                                <Gallery slides={productsSaszetki} onChange={(sliderNumber) => {onChangeSlideEvent(sliderNumber, 1)}}/>
                            </div>
                            <div>
                                <Text hidden={activeSlide[1][0]}>
                                    <Header headerSize="3" title="Saszetki"/>
                                    <p>
                                        <img src={ImgCat} width="90" height="70"/>
                                    </p>
                                    <p className="text-big">
                                        TULA – FILECIKI Z&nbsp;KURCZAKIEM  W&nbsp;SOSIE DLA KOTA
                                        <br/>
                                        <br/>
                                    </p>
                                    <div className="icons-row">
                                        <div>
                                            <img src={Procent88wFilecikach} width="76" height="76" alt="88% w mięsa filecikach"/>
                                        </div>
                                        <div>
                                            <img src={Inulina} width="76" height="76" alt="Inulina"/>
                                        </div>
                                    </div>
                                    <p>
                                        Karma zawiera 88%&nbsp;mięsa i&nbsp;produktów pochodzenia zwierzęcego w&nbsp;filecikach*
                                        <br/><br/>
                                        W&nbsp;produkcie znajdują się  również warzywa, w&nbsp;tym między innymi suszone pomidory, a&nbsp;także zioła takie jak tymianek i&nbsp;pietruszka. Saszetki zawierają także inulinę – prebiotyk, który wspomaga układ pokarmowy zwierzaka. Karma nie zawiera substancji konserwujących i&nbsp;barwników. Sprawdza się w&nbsp;codziennym żywieniu kota.
                                        <br/><br/>
                                        <b>*fileciki standardowo stanowią 34,5%&nbsp;produktu</b>
                                        <br/><br/>
                                        Masa netto: 85g
                                    </p>
                                    <a data-inactive-label="Pokaż pełen skład" data-active-label="Ukryj pełen skład" onClick={toggleMoreDesc}>Pokaż pełen skład</a>

                                    <p className="toggle js-toggle">
                                        Skład: mięso i&nbsp;produkty pochodzenia zwierzęcego (88%&nbsp;w&nbsp;filecikach*, z&nbsp;czego 40,6%&nbsp;kurczaka, w&nbsp;tym 11,6%&nbsp;mięso odkostnione mechanicznie), warzywa (0,45%&nbsp;suszonych pomidorów, co odpowiada 4%&nbsp;pomidorów), substancje mineralne, produkty pochodzenia roślinnego (0,4%&nbsp;inuliny), zioła 0,05%&nbsp;(0,025%&nbsp;tymianku, 0,025%&nbsp;pietruszki).
                                    </p>
                                </Text>
                                <Text hidden={activeSlide[1][1]}>
                                    <Header headerSize="3" title="Saszetki"/>
                                    <p>
                                        <img src={ImgCat} width="90" height="70"/>
                                    </p>
                                    <p className="text-big">
                                        TULA – FILECIKI Z&nbsp;WOŁOWINĄ W&nbsp;SOSIE DLA&nbsp;KOTA
                                        <br/>
                                        <br/>
                                    </p>
                                    <div className="icons-row">
                                        <div>
                                            <img src={Procent88wFilecikach} width="76" height="76" alt="88% w mięsa filecikach"/>
                                        </div>
                                        <div>
                                            <img src={Inulina} width="76" height="76" alt="Inulina"/>
                                        </div>
                                    </div>
                                    <p>
                                        Karma zawiera 88%&nbsp;mięsa i&nbsp;produktów pochodzenia zwierzęcego w&nbsp;filecikach*
                                        <br/><br/>
                                        W&nbsp;karmie znajdują się  także warzywa – takie jak suszone pomidory, jak również zioła – rozmaryn, tymianek oraz inulina – prebiotyk, która wspomaga pracę układu pokarmowego. Karma nie zawiera substancji konserwujących i&nbsp;barwników. Sprawdza się w&nbsp;codziennym żywieniu kota.
                                        <br/><br/>
                                        <b>*fileciki standardowo stanowią 34,5%&nbsp;produktu</b>
                                        <br/>
                                        <br/>
                                        Masa netto: 85g
                                    </p>
                                    <a data-inactive-label="Pokaż pełen skład" data-active-label="Ukryj pełen skład" onClick={toggleMoreDesc}>Pokaż pełen skład</a>

                                    <p className="toggle js-toggle">
                                        mięso i&nbsp;produkty pochodzenia zwierzęcego* (88%&nbsp;w&nbsp;filecikach*, z&nbsp;czego 40,6%&nbsp;wołowiny, w&nbsp;tym 11,6%&nbsp;wątroby wołowej), warzywa (0,45%&nbsp;suszonych pomidorów, co odpowiada 4%&nbsp;pomidorów), substancje mineralne, produkty pochodzenia roślinnego (0,4%&nbsp;inuliny), zioła 0,05%&nbsp;(0,025%&nbsp;rozmarynu, 0,025%&nbsp;tymianku).
                                    </p>
                                </Text>
                                <Text hidden={activeSlide[1][2]}>
                                    <Header headerSize="3" title="Saszetki"/>
                                    <p>
                                        <img src={ImgCat} width="90" height="70"/>
                                    </p>
                                    <p className="text-big">
                                        TULA – FILECIKI Z&nbsp;INDYKIEM W&nbsp;SOSIE DLA&nbsp;KOTA
                                        <br/>
                                        <br/>
                                    </p>
                                    <div className="icons-row">
                                        <div>
                                            <img src={Procent88wFilecikach} width="76" height="76" alt="88% w mięsa filecikach"/>
                                        </div>
                                        <div>
                                            <img src={Inulina} width="76" height="76" alt="Inulina"/>
                                        </div>
                                        <div>
                                            <img src={Rokitnik} width="76" height="76" alt="Rokitnik"/>
                                        </div>
                                    </div>
                                    <p>
                                        Karma zawiera 88%&nbsp;mięsa i&nbsp;produktów pochodzenia zwierzęcego w&nbsp;filecikach*
                                        <br/><br/>
                                        W&nbsp;karmie znajdują się również substancje mineralne oraz produkty pochodzenia roślinnego takie jak inulina, która wspomaga prawidłowe  funkcjonowanie układu pokarmowego. Karma nie zawiera substancji konserwujących i&nbsp;barwników.  Sprawdza się w&nbsp;codziennym żywieniu kota.
                                        <br/><br/>
                                        <b>*fileciki standardowo stanowią 34,5%&nbsp;produktu</b>
                                        <br/>
                                        <br/>
                                        Masa netto: 85g
                                    </p>
                                    <a data-inactive-label="Pokaż pełen skład" data-active-label="Ukryj pełen skład" onClick={toggleMoreDesc}>Pokaż pełen skład</a>

                                    <p className="toggle js-toggle">
                                        Skład: mięso i&nbsp;produkty pochodzenia zwierzęcego (88%&nbsp;w&nbsp;filecikach*, z&nbsp;czego 40,6%&nbsp;indyka, w&nbsp;tym 11,6%&nbsp;korpusu indyka), substancje mineralne, oleje i&nbsp;tłuszcze (0,5%&nbsp;oleju lnianego), produkty pochodzenia roślinnego (0,4%&nbsp;inuliny), rokitnik (0,05%).
                                    </p>
                                </Text>
                                <Text hidden={activeSlide[1][3]}>
                                    <Header headerSize="3" title="Saszetki"/>
                                    <p>
                                        <img src={ImgCat} width="90" height="70"/>
                                    </p>
                                    <p className="text-big">
                                        TULA – FILECIKI Z&nbsp;ŁOSOSIEM W&nbsp;SOSIE DLA&nbsp;KOTA
                                        <br/>
                                        <br/>
                                    </p>
                                    <div className="icons-row">
                                        <div>
                                            <img src={Procent74wFilecikach} width="76" height="76" alt="74% w mięsa filecikach"/>
                                        </div>
                                        <div>
                                            <img src={Inulina} width="76" height="76" alt="Inulina"/>
                                        </div>
                                    </div>
                                    <p>
                                        Karma zawiera 74%&nbsp;mięsa i&nbsp;produktów pochodzenia zwierzęcego w&nbsp;filecikach*.
                                        <br/><br/>
                                        W&nbsp;karmie znajdują się również substancje mineralne oraz produkty pochodzenia roślinnego takie jak np. inulina, która wspomaga prawidłowe funkcjonowanie układu pokarmowego. Karma nie zawiera substancji konserwujących i&nbsp;barwników. Sprawdza się w&nbsp;codziennym żywieniu kota.
                                        <br/><br/>
                                        <b>*fileciki standardowo stanowią 34,5%&nbsp;produktu</b>
                                        <br/>
                                        <br/>
                                        Masa netto: 85g
                                    </p>
                                    <a data-inactive-label="Pokaż pełen skład" data-active-label="Ukryj pełen skład" onClick={toggleMoreDesc}>Pokaż pełen skład</a>

                                    <p className="toggle js-toggle">
                                        Skład: mięso i&nbsp;produkty pochodzenia zwierzęcego (74%&nbsp;w&nbsp;filecikach*), ryby i&nbsp;produkty rybne (14%&nbsp;łososia w&nbsp;filecikach*), substancje mineralne, produkty pochodzenia roślinnego (0,4%&nbsp;inuliny), spirulina 0,05%.
                                    </p>
                                </Text>
                            </div>
                        </div>
                    </div>
                </div>
                {/*Flowpack*/}
                <div tabIndex={2} className="tab">
                    <div className="content">
                        <div className="slider-row">
                            <div>
                                <Gallery slides={productsFlowpack} onChange={(sliderNumber) => {onChangeSlideEvent(sliderNumber, 2)}}/>
                            </div>
                            <div>
                                <Text hidden={activeSlide[2][0]}>
                                    <Header headerSize="3" title="Flowpack"/>
                                    <p>
                                        <img src={ImgCat} width="90" height="70"/>
                                    </p>
                                    <p className="text-big">
                                        TULA – FILECIKI DLA KOTA: Z&nbsp;KURCZAKIEM W&nbsp;SOSIE, Z&nbsp;WOŁOWINĄ W&nbsp;SOSIE
                                        <br/>
                                        <br/>
                                    </p>
                                    <div className="icons-row">
                                        <div>
                                            <img src={Procent88wFilecikach} width="76" height="76" alt="88% w mięsa filecikach"/>
                                        </div>
                                        <div>
                                            <img src={Inulina} width="76" height="76" alt="Inulina"/>
                                        </div>
                                    </div>
                                    <p>
                                        Karma zawiera 88%&nbsp;mięsa i&nbsp;produktów pochodzenia zwierzęcego w&nbsp;filecikach*.
                                        <br/><br/>
                                        W&nbsp;produkcie znajdują się  również warzywa, w&nbsp;tym między innymi suszone pomidory oraz zioła takie jak tymianek. Saszetki zawierają także inulinę  – prebiotyk, który wspomaga układ pokarmowy zwierzaka. Karma nie zawiera substancji konserwujących i&nbsp;barwników. Sprawdza się w&nbsp;codziennym żywieniu kota.
                                        <br/><br/>
                                        <b>*fileciki standardowo stanowią 34,5%&nbsp;produktu</b>
                                        <br/>
                                        <br/>
                                        Masa netto: 4x85g
                                    </p>

                                    <a data-inactive-label="Pokaż pełen skład" data-active-label="Ukryj pełen skład" onClick={toggleMoreDesc}>Pokaż pełen skład</a>

                                    <p className="toggle js-toggle">
                                        Saszetka z&nbsp;kurczakiem skład:<br/><br/>
                                        mięso i&nbsp;produkty pochodzenia zwierzęcego (88%&nbsp;w&nbsp;filecikach*, z&nbsp;czego 40,6%&nbsp;kurczaka, w&nbsp;tym 11,6%&nbsp;mięso odkostnione mechanicznie), warzywa (0,45%&nbsp;suszonych pomidorów, co odpowiada 4%&nbsp;pomidorów), substancje mineralne, produkty pochodzenia roślinnego (0,4%&nbsp;inuliny), zioła 0,05%&nbsp;(0,025%&nbsp;tymianku, 0,025%&nbsp;pietruszki).
                                        <br/>
                                        <br/>
                                        Saszetka z&nbsp;wołowiną skład:<br/><br/>
                                        mięso i&nbsp;produkty pochodzenia zwierzęcego (88%&nbsp;w&nbsp;filecikach*, z&nbsp;czego 40,6%&nbsp;wołowiny, w&nbsp;tym 11,6%&nbsp;wątroby wołowej), warzywa (0,45%&nbsp;suszonych pomidorów, co odpowiada 4%&nbsp;pomidorów), substancje mineralne, produkty pochodzenia roślinnego (0,4%&nbsp;inuliny), zioła 0,05%&nbsp;(0,025%&nbsp;rozmarynu, 0,025%&nbsp;tymianku).
                                    </p>
                                </Text>
                                <Text hidden={activeSlide[2][1]}>
                                    <Header headerSize="3" title="Flowpack"/>
                                    <p>
                                        <img src={ImgCat} width="90" height="70"/>
                                    </p>
                                    <p className="text-big">
                                        TULA – FILECIKI DLA KOTA: Z&nbsp;INDYKIEM W&nbsp;SOSIE, Z&nbsp;ŁOSOSIEM  W&nbsp;SOSIE
                                        <br/>
                                        <br/>
                                    </p>
                                    <div className="icons-row">
                                        <div>
                                            <img src={Procent74wFilecikach} width="76" height="76" alt="74% w mięsa filecikach"/>
                                        </div>
                                        <div>
                                            <img src={Procent88wFilecikach} width="76" height="76" alt="88% w mięsa filecikach"/>
                                        </div>
                                        <div>
                                            <img src={Inulina} width="76" height="76" alt="Inulina"/>
                                        </div>
                                        <div>
                                            <img src={Rokitnik} width="76" height="76" alt="Rokitnik"/>
                                        </div>
                                    </div>
                                    <p>
                                        Karma z&nbsp;łososiem zawiera 74%&nbsp;mięsa i&nbsp;produktów pochodzenia zwierzęcego w&nbsp;filecikach*, natomiast w&nbsp;wariancie z&nbsp;indykiem znajduje się 88%&nbsp;mięsa i&nbsp;produktów pochodzenia zwierzęcego w&nbsp;filecikach*. W&nbsp;karmie występują również  substancje mineralne oraz produkty pochodzenia roślinnego takie jak np. inulina, która wspomaga prawidłowe funkcjonowanie układu pokarmowego. Pozostała część karmy to między innymi warzywa – takie jak suszone pomidory oraz zioła – rozmaryn i&nbsp;tymianek. Karma nie zawiera substancji konserwujących i&nbsp;barwników. Sprawdza się w&nbsp;codziennym żywieniu kota.
                                        <br/><br/>
                                        <b>*fileciki standardowo stanowią 34,5%&nbsp;produktu</b>
                                        <br/>
                                        <br/>
                                        Masa netto: 4x85g
                                    </p>

                                    <a data-inactive-label="Pokaż pełen skład" data-active-label="Ukryj pełen skład" onClick={toggleMoreDesc}>Pokaż pełen skład</a>

                                    <p className="toggle js-toggle">
                                        Saszetka z&nbsp;łososiem skład:
                                        <br/><br/>
                                        mięso i&nbsp;produkty pochodzenia zwierzęcego (74%&nbsp;w&nbsp;filecikach*), ryby i&nbsp;produkty rybne (14%&nbsp;łososia w&nbsp;filecikach*), substancje mineralne, produkty pochodzenia roślinnego (0,4%&nbsp;inuliny), spirulina 0,05%.
                                        <br/><br/>
                                        Saszetka z&nbsp;indykiem skład:<br/><br/>
                                        mięso i&nbsp;produkty pochodzenia zwierzęcego (88%&nbsp;w&nbsp;filecikach*, z&nbsp;czego 40,6%&nbsp;indyka, w&nbsp;tym 11,6%&nbsp;korpusu indyka), substancje mineralne, oleje i&nbsp;tłuszcze (0,5%&nbsp;oleju lnianego), produkty pochodzenia roślinnego (0,4%&nbsp;inuliny), rokitnik (0,05%).
                                    </p>
                                </Text>
                            </div>
                        </div>
                    </div>
                </div>
                {/*Pasztety - nieaktywna*/}
                <div tabIndex={3} className="tab">
                    <div className="content">
                        <div className="slider-row">
                            <div>
                                <Gallery slides={products} onChange={(sliderNumber) => {onChangeSlideEvent(sliderNumber, 3)}}/>
                            </div>
                            <div>
                                <Text hidden={activeSlide[3][0]}>
                                    <Header headerSize="3" title="Flowpacki 1"/>
                                    <p>
                                        <img src={ImgCat} width="90" height="70"/>
                                    </p>
                                    <p className="text-big">
                                        TULA – karma pełnoporcjowa sucha dla&nbsp;psów dorosłych małych i&nbsp;średnich&nbsp;ras z&nbsp;wołowiną i&nbsp;warzywami
                                        <br/>
                                        <br/>
                                    </p>
                                    <div className="icons-row">
                                        <div>
                                            <img src={Icon55Miesa} width="76" height="76" alt="55% mięsa"/>
                                        </div>
                                        <div>
                                            <img src={IconWitaminaE} width="76" height="76" alt="55% mięsa"/>
                                        </div>
                                        <div>
                                            <img src={IconWitaminaD3} width="76" height="76" alt="55% mięsa"/>
                                        </div>
                                    </div>
                                    <p>
                                        Karma zawiera 55%&nbsp;mięsa i&nbsp;produktów pochodzenia zwierzęcego.
                                        Zawiera również witaminę&nbsp;D<sub>3</sub> dla mocnych kości i&nbsp;zdrowych zębów oraz witaminę&nbsp;E wspierającą układ odpornościowy psa.
                                        W&nbsp;składzie karmy znajdują&nbsp;się również warzywa i&nbsp;owoce takie&nbsp;jak:
                                        marchew suszona, brokuły suszone, pomidory suszone, szpinak suszony, żurawinę suszoną i&nbsp;jagody suszone.
                                        Sprawdza&nbsp;się w&nbsp;codziennym żywieniu&nbsp;psów.
                                        <br/>
                                        <br/>
                                        Masa netto: 300g
                                    </p>
                                </Text>
                                <Text hidden={activeSlide[3][1]}>
                                    <Header headerSize="3" title="Flowpacki 2"/>
                                    <p>
                                        <img src={ImgCat} width="90" height="70"/>
                                    </p>
                                    <p className="text-big">
                                        TULA – karma pełnoporcjowa sucha dla&nbsp;psów dorosłych małych i&nbsp;średnich&nbsp;ras z&nbsp;wołowiną i&nbsp;warzywami
                                        <br/>
                                        <br/>
                                    </p>
                                    <div className="icons-row">
                                        <div>
                                            <img src={Icon55Miesa} width="76" height="76" alt="55% mięsa"/>
                                        </div>
                                        <div>
                                            <img src={IconWitaminaE} width="76" height="76" alt="55% mięsa"/>
                                        </div>
                                        <div>
                                            <img src={IconWitaminaD3} width="76" height="76" alt="55% mięsa"/>
                                        </div>
                                    </div>
                                    <p>
                                        Karma zawiera 55%&nbsp;mięsa i&nbsp;produktów pochodzenia zwierzęcego.
                                        Zawiera również witaminę&nbsp;D<sub>3</sub> dla mocnych kości i&nbsp;zdrowych zębów oraz witaminę&nbsp;E wspierającą układ odpornościowy psa.
                                        W&nbsp;składzie karmy znajdują&nbsp;się również warzywa i&nbsp;owoce takie&nbsp;jak:
                                        marchew suszona, brokuły suszone, pomidory suszone, szpinak suszony, żurawinę suszoną i&nbsp;jagody suszone.
                                        Sprawdza&nbsp;się w&nbsp;codziennym żywieniu&nbsp;psów.
                                        <br/>
                                        <br/>
                                        Masa netto: 300g
                                    </p>
                                </Text>
                                <Text hidden={activeSlide[3][2]}>
                                    <Header headerSize="3" title="Flowpacki 3"/>
                                    <p>
                                        <img src={ImgCat} width="90" height="70"/>
                                    </p>
                                    <p className="text-big">
                                        TULA – karma pełnoporcjowa sucha dla&nbsp;psów dorosłych małych i&nbsp;średnich&nbsp;ras z&nbsp;wołowiną i&nbsp;warzywami
                                        <br/>
                                        <br/>
                                    </p>
                                    <div className="icons-row">
                                        <div>
                                            <img src={Icon55Miesa} width="76" height="76" alt="55% mięsa"/>
                                        </div>
                                        <div>
                                            <img src={IconWitaminaE} width="76" height="76" alt="55% mięsa"/>
                                        </div>
                                        <div>
                                            <img src={IconWitaminaD3} width="76" height="76" alt="55% mięsa"/>
                                        </div>
                                    </div>
                                    <p>
                                        Karma zawiera 55%&nbsp;mięsa i&nbsp;produktów pochodzenia zwierzęcego.
                                        Zawiera również witaminę&nbsp;D<sub>3</sub> dla mocnych kości i&nbsp;zdrowych zębów oraz witaminę&nbsp;E wspierającą układ odpornościowy psa.
                                        W&nbsp;składzie karmy znajdują&nbsp;się również warzywa i&nbsp;owoce takie&nbsp;jak:
                                        marchew suszona, brokuły suszone, pomidory suszone, szpinak suszony, żurawinę suszoną i&nbsp;jagody suszone.
                                        Sprawdza&nbsp;się w&nbsp;codziennym żywieniu&nbsp;psów.
                                        <br/>
                                        <br/>
                                        Masa netto: 300g
                                    </p>
                                </Text>
                                <Text hidden={activeSlide[3][3]}>
                                    <Header headerSize="3" title="Flowpacki 4"/>
                                    <p>
                                        <img src={ImgCat} width="90" height="70"/>
                                    </p>
                                    <p className="text-big">
                                        TULA – karma pełnoporcjowa sucha dla&nbsp;psów dorosłych małych i&nbsp;średnich&nbsp;ras z&nbsp;wołowiną i&nbsp;warzywami
                                        <br/>
                                        <br/>
                                    </p>
                                    <div className="icons-row">
                                        <div>
                                            <img src={Icon55Miesa} width="76" height="76" alt="55% mięsa"/>
                                        </div>
                                        <div>
                                            <img src={IconWitaminaE} width="76" height="76" alt="55% mięsa"/>
                                        </div>
                                        <div>
                                            <img src={IconWitaminaD3} width="76" height="76" alt="55% mięsa"/>
                                        </div>
                                    </div>
                                    <p>
                                        Karma zawiera 55%&nbsp;mięsa i&nbsp;produktów pochodzenia zwierzęcego.
                                        Zawiera również witaminę&nbsp;D<sub>3</sub> dla mocnych kości i&nbsp;zdrowych zębów oraz witaminę&nbsp;E wspierającą układ odpornościowy psa.
                                        W&nbsp;składzie karmy znajdują&nbsp;się również warzywa i&nbsp;owoce takie&nbsp;jak:
                                        marchew suszona, brokuły suszone, pomidory suszone, szpinak suszony, żurawinę suszoną i&nbsp;jagody suszone.
                                        Sprawdza&nbsp;się w&nbsp;codziennym żywieniu&nbsp;psów.
                                        <br/>
                                        <br/>
                                        Masa netto: 300g
                                    </p>
                                </Text>
                            </div>
                        </div>
                    </div>
                </div>
            </Tabs>
        </Screen>
    </>);
};

export default CatsProducts;
