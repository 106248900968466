import React from "react";
import "./Button.scss";

const Button = ({label, link = "", onClick = () => {}}) => {
  return(<>
    <a href={link} onClick={onClick} className="button">
      <div className="button__label">{label}</div>
      <svg className="button__background">
        <defs>
          <clipPath id="clip-path">
            <rect id="Rectangle_93" data-name="Rectangle 93"/>
          </clipPath>
        </defs>
        <g id="Group_245" data-name="Group 245" clipPath="url(#clip-path)">
          <path id="Path_6" data-name="Path 6" d="M93.444.143H77.529c-5.03,0-10.864.484-16.9.484C58,.628,55.341.143,52.689.143c-2.553,0-5.1.484-7.589.484H38.545c-2.283,0-4.48-.484-6.555-.484H15.012a25.946,25.946,0,0,0-8.452,1C5.546,1.435-.6,4.276.137,6.028a12.726,12.726,0,0,1,0,3.588V24.4a12.726,12.726,0,0,1,0,3.588c-.676,1.292,1.353,2.3,1.69,3.445,0,1,.339,2.009-.337,3.014-.339,1,1.353,1.865,2.028,2.727s2.705,1,4.4,1.435c3.043.718,6.424.144,9.467.287a6.908,6.908,0,0,0,3.38-.43c7.709-.687,5.085-.175,14.374.044,1.351-.144,5.844.674,6.857.243,1.353-.573,3.043-.287,4.4-.287,0,.005,5.873.311,15.211.494,2.434.048,5.1-.479,7.966-.456,2.724.023,6.166.435,9.934.277,3.714-.157,7.744-.317,11.718-.315,4.229,0,8.548,0,12.856,0l9.783,0c5.433,0,10.721.284,15.652.284,3.892,0,7.559-.284,10.9-.284h18.557c1.359,0,10.026-.036,18.61-.054,8.494-.017,16.907-.017,18.085.054,3.043.287,4.4-.861,7.1-.718,2.366.144,4.733,0,7.1,0h7.1c2.7.144,5.409-.286,7.775.287,2.029.718,4.4.43,6.762.43,4.507-.319,8.287.537,8.854.327,3.08-1.134,1.992.292,6.425.1,4.058,0,7.775.143,11.833-.143,2.7-.144,5.409-.574,6.762-2.01.675-.718,1.69-1.435,1.351-2.44,0-9.894,1.758-31.354.068-32.215-2.029-1-6.829-1.514-9.872-1.37-2.7.143-5.07.143-7.775.143-.676-.143-2.028,0-2.366-.287-1.353-.718-3.043-.43-4.733-.43H230.206c-2.028,0-4.056.143-5.747,0-2.366-.43-3.38.718-5.409.718-2.366-.143-4.733-.43-6.762.574,0,.143-1.014.143-1.353,0C209.585.573,207.894.861,206.2.861h-30.7c-3.664,0-7.492.484-11.385.484-8.781,0-17.894-.484-26.219-.484H106.291c-1.69,0-3.043-.143-4.733-.143C99.53-.431,96.487.143,93.444.143M76.2,6.171c-1.353,0-2.029-.143-2.029-.718,0-.43.337-.574,1.353-.574,1.014-.143,1.69.144,1.69.574s-.339.574-1.014.718m135,29.567c-1.353.144-2.028-.143-2.028-.573s.337-.574,1.351-.718,1.691.143,1.691.718c0,.287-.339.573-1.015.573M151.592,7.176c0-.143.337-.287.676-.43,0,.143.337.287.337.287,0,.143-.337.287-.337.43-.339,0-.676-.143-.676-.287"/>
        </g>
      </svg>
      <svg className="button__background">
        <defs>
          <clipPath id="clip-path">
            <rect id="Rectangle_93" data-name="Rectangle 93"/>
          </clipPath>
        </defs>
        <g id="Group_245" data-name="Group 245" clipPath="url(#clip-path)">
          <path id="Path_6" data-name="Path 6" d="M93.444.143H77.529c-5.03,0-10.864.484-16.9.484C58,.628,55.341.143,52.689.143c-2.553,0-5.1.484-7.589.484H38.545c-2.283,0-4.48-.484-6.555-.484H15.012a25.946,25.946,0,0,0-8.452,1C5.546,1.435-.6,4.276.137,6.028a12.726,12.726,0,0,1,0,3.588V24.4a12.726,12.726,0,0,1,0,3.588c-.676,1.292,1.353,2.3,1.69,3.445,0,1,.339,2.009-.337,3.014-.339,1,1.353,1.865,2.028,2.727s2.705,1,4.4,1.435c3.043.718,6.424.144,9.467.287a6.908,6.908,0,0,0,3.38-.43c7.709-.687,5.085-.175,14.374.044,1.351-.144,5.844.674,6.857.243,1.353-.573,3.043-.287,4.4-.287,0,.005,5.873.311,15.211.494,2.434.048,5.1-.479,7.966-.456,2.724.023,6.166.435,9.934.277,3.714-.157,7.744-.317,11.718-.315,4.229,0,8.548,0,12.856,0l9.783,0c5.433,0,10.721.284,15.652.284,3.892,0,7.559-.284,10.9-.284h18.557c1.359,0,10.026-.036,18.61-.054,8.494-.017,16.907-.017,18.085.054,3.043.287,4.4-.861,7.1-.718,2.366.144,4.733,0,7.1,0h7.1c2.7.144,5.409-.286,7.775.287,2.029.718,4.4.43,6.762.43,4.507-.319,8.287.537,8.854.327,3.08-1.134,1.992.292,6.425.1,4.058,0,7.775.143,11.833-.143,2.7-.144,5.409-.574,6.762-2.01.675-.718,1.69-1.435,1.351-2.44,0-9.894,1.758-31.354.068-32.215-2.029-1-6.829-1.514-9.872-1.37-2.7.143-5.07.143-7.775.143-.676-.143-2.028,0-2.366-.287-1.353-.718-3.043-.43-4.733-.43H230.206c-2.028,0-4.056.143-5.747,0-2.366-.43-3.38.718-5.409.718-2.366-.143-4.733-.43-6.762.574,0,.143-1.014.143-1.353,0C209.585.573,207.894.861,206.2.861h-30.7c-3.664,0-7.492.484-11.385.484-8.781,0-17.894-.484-26.219-.484H106.291c-1.69,0-3.043-.143-4.733-.143C99.53-.431,96.487.143,93.444.143M76.2,6.171c-1.353,0-2.029-.143-2.029-.718,0-.43.337-.574,1.353-.574,1.014-.143,1.69.144,1.69.574s-.339.574-1.014.718m135,29.567c-1.353.144-2.028-.143-2.028-.573s.337-.574,1.351-.718,1.691.143,1.691.718c0,.287-.339.573-1.015.573M151.592,7.176c0-.143.337-.287.676-.43,0,.143.337.287.337.287,0,.143-.337.287-.337.43-.339,0-.676-.143-.676-.287"/>
        </g>
      </svg>
    </a>
  </>);
};

export default Button;
