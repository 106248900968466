import React, {useEffect, useState} from "react";
import "./Menu.scss";
import Logo from "./images/logo.png";
import Hamburger from "../Hamburger";

import {useScrollSection,} from 'react-scroll-section';

const Menu = () => {

    const [active, setActive] = useState(false);
    const [prevSection, setPrevSection] = useState(null);
    const [nextSection, setNextSection] = useState(null);

    const toggleMobileMenu = () => {
        setActive(!active);
    };

    const sections = {
        homeSection: useScrollSection('MainSection'),
        aboutSection: useScrollSection('AboutSection'),
        foodSection: useScrollSection('FoodSection'),
        dogsProductsSection: useScrollSection('DogsProductsSection'),
        catsProductsSection: useScrollSection('CatsProductsSection'),
        whereSection: useScrollSection('WhereSection'),
    };

    useEffect(() => {
        if (window.innerWidth < 1024) {
            return;
        }

        let options = {
            rootMargin: '0px',
            threshold: 0.5
        }

        const callback = (entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('current');
                    setPrevSection(entry.target.previousElementSibling ?? entry.target);
                    setNextSection(entry.target.nextElementSibling ?? entry.target);
                } else {
                    entry.target.classList.remove('current');
                }
            });
        };

        const MainSectionObserver = new IntersectionObserver(callback, options);
        const AboutSectionObserver = new IntersectionObserver(callback, options);
        const FoodSectionObserver = new IntersectionObserver(callback, options);
        const DogsProductsSectionObserver = new IntersectionObserver(callback, options);
        const CatsProductsSectionObserver = new IntersectionObserver(callback, options);
        const WhereSectionObserver = new IntersectionObserver(callback, options);

        MainSectionObserver.observe(document.getElementById("MainSection"));
        AboutSectionObserver.observe(document.getElementById("AboutSection"));
        FoodSectionObserver.observe(document.getElementById("FoodSection"));
        DogsProductsSectionObserver.observe(document.getElementById("DogsProductsSection"));
        CatsProductsSectionObserver.observe(document.getElementById("CatsProductsSection"));
        WhereSectionObserver.observe(document.getElementById("WhereSection"));
    });

    return (
        <nav className={"menu" + (active ? " menu--open" : "")}>
            <img src={Logo} className="menu__logo" alt="TULA"/>
            <div className="menu__hamburger menu__hamburger--top">
                <Hamburger active={active} clickHandle={toggleMobileMenu}/>
            </div>
            <ul className="menu__list">
                <li className={`menu__element ${(sections.homeSection.selected) ? "active" : ""}`} onClick={() => {
                    toggleMobileMenu();
                    sections.homeSection.onClick();
                }} selected={sections.homeSection.selected}><span>Home</span></li>
                <li className={`menu__element ${(sections.aboutSection.selected) ? "active" : ""}`} onClick={() => {
                    toggleMobileMenu();
                    sections.aboutSection.onClick();
                }} selected={sections.aboutSection.selected}><span>Dla kogo jest TULA?</span></li>
                <li className={`menu__element ${(sections.foodSection.selected) ? "active" : ""}`} onClick={() => {
                    toggleMobileMenu();
                    sections.foodSection.onClick();
                }} selected={sections.foodSection.selected}><span>Jakie są karmy TULA?</span></li>
                <li className={`menu__element ${(sections.dogsProductsSection.selected) ? "active" : ""}`} onClick={() => {
                    toggleMobileMenu();
                    sections.dogsProductsSection.onClick();
                }} selected={sections.dogsProductsSection.selected}><span>Karmy dla psa</span></li>
                <li className={`menu__element ${(sections.catsProductsSection.selected) ? "active" : ""}`} onClick={() => {
                    toggleMobileMenu();
                    sections.catsProductsSection.onClick();
                }} selected={sections.catsProductsSection.selected}><span>Karmy dla kota</span></li>
                <li className={`menu__element ${(sections.whereSection.selected) ? "active" : ""}`} onClick={() => {
                    toggleMobileMenu();
                    sections.whereSection.onClick();
                }} selected={sections.whereSection.selected}><span>Znajdź nas</span></li>
            </ul>
            <div className="menu__hamburger menu__hamburger--bottom">
                <Hamburger active={active} clickHandle={toggleMobileMenu}/>
            </div>
        </nav>
    );
};

export default Menu;
