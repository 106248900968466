import React from "react";
import "./Image.scss";

const Image = ({image, alt, expand = false}) => {
  return(<div className={"image" + (expand ? " image--expand" : "")}>
    <img src={image} alt={alt}/>
  </div>);
};

export default Image;
