import React from "react";
import "./Header.scss";

const Header = ({headerSize, title, extraClass}) => {
  const customHeader = React.createElement(`h${headerSize}`, {
    dangerouslySetInnerHTML: { __html: title }
  });

  return(<div className={`header ${extraClass ? extraClass : ''}`}>
    {customHeader}
  </div>);
};

export default Header;
