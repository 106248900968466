import {Carousel} from 'react-responsive-carousel';

import "react-responsive-carousel/lib/styles/carousel.min.css";
import "./Gallery.scss";

const Gallery = ({slides, onChange = () => {}}) => {

    const createSlides = () => {
        return slides.map((slide, key) => {
            return (
                <div key={key}>
                    <img src={slide.image} alt={slide.alt}/>
                </div>
            );
        });
    };

    return (
        <div className="gallery">
            <Carousel showThumbs={true} showArrows={true} showStatus={false} onChange={onChange}>
                {createSlides()}
            </Carousel>
        </div>
    );
}

export default Gallery;
