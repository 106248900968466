import React from "react";
import Header from "../sections/Header";
import Screen from "../sections/Screen";

import Text from "../sections/Text";
import karmy from "../images/o-tuli/karmy.png";

const Food = () => {
    return (<>
        <Screen>
            <Header headerSize="2" title="Jakie są karmy TULA?"/>
            <div className="content">
                <div className="karmy">
                    <br/>
                    <br/>
                    <div className="image-wrapper">
                        <img src={karmy} alt=""/>
                        <div className="zdjecie-symboliczne">Zdjęcie symboliczne</div>
                    </div>
                    <Text>
                        <p>
                            TULA jest dostępna w wielu wersjach smakowych, w&nbsp;postaci suchej i&nbsp;mokrej&nbsp;karmy.
                            W&nbsp;asortymencie TULI znajdują&nbsp;się karmy <b>z&nbsp;kurczakiem, indykiem, jagnięciną, wołowiną i&nbsp;łososiem</b>.
                            W&nbsp;karmach TULI (w&nbsp;wybranych rodzajach) znajdują&nbsp;się też warzywa, zioła, owoce, witaminy i&nbsp;mikroskładniki, a&nbsp;także niezbędne oleje i&nbsp;tłuszcze.
                            <br/>
                            <br/>
                            Saszetki dla&nbsp;kota i&nbsp;psa zawierają między innymi <b>inulinę</b> – prebiotyk, który wspomaga układ pokarmowy&nbsp;zwierząt.
                            Natomiast karma sucha zawiera <b>witaminę&nbsp;D<sub>3</sub></b> dla&nbsp;mocnych kości i&nbsp;zdrowych zębów oraz witaminę&nbsp;E, która wspiera układ odpornościowy spożywającego ją kota&nbsp;lub&nbsp;psa.
                            <br/>
                            <br/>
                            <div className="text-big">
                                TULA – NATURALNIE, ŻE Z&nbsp;MIĘSEM
                            </div>
                        </p>
                    </Text>
                </div>
            </div>
        </Screen>
    </>);
};

export default Food;
