import React from 'react';
import ReactDOM from 'react-dom';
import {render} from 'react-snapshot';
import App from './App';
import reportWebVitals from './reportWebVitals';

render(
    <App/>,
    document.getElementById('root')
);

reportWebVitals();
