import React from "react";
import Header from "../sections/Header";
import Button from "../sections/Button";
import Screen from "../sections/Screen";

// import ImageFile from "../images/01.png";
import ImageFile from "../images/home-02.png";
import ImageFileMobile from "../images/home-02-mobile.png";

const Main = () => {
    return (<>
        <Screen>
            <Header headerSize="1" title="Troskliwi z&nbsp;natury" extraClass="--green"/>

            <div className="image-wrapper">
                <div className="image image--expand --mobile">
                    <img src={ImageFileMobile} alt="Troskliwi z natury"/>
                </div>
                <div className="image image--expand --desktop">
                    <img src={ImageFile} alt="Troskliwi z natury"/>
                </div>
                <div className="zdjecie-symboliczne">Zdjęcie symboliczne</div>
            </div>

            <Button link="#" label="Sprawdź"></Button>
        </Screen>
    </>);
};

export default Main;
