import React from "react";
import Header from "../sections/Header";
import Image from "../sections/Image";
import Screen from "../sections/Screen";
import Text from "../sections/Text";
import tula1 from "../images/o-tuli/tula1.jpg";
import tula2 from "../images/o-tuli/tula2.jpg";

const About = () => {
    return (<>
        <Screen>
            <Header headerSize="2" title="Dla kogo jest TULA?"/>
            <div className="content">
                <div className="o-tuli">
                    <div className="o-tuli__inner-1">
                        <Text>
                            <p>
                                Czy pies lub&nbsp;kot są&nbsp;Twoimi przyjaciółmi, towarzyszami Twojego codziennego życia?
                                I&nbsp;traktujesz&nbsp;je jak <b>członków swojej&nbsp;rodziny</b>?
                                <br/>
                                Bo dla&nbsp;nas są&nbsp;ważną, a&nbsp;nawet nieodłączną częścią naszego&nbsp;życia.
                                <br/>
                                Chcemy by&nbsp;jadły <b>zdrowo i&nbsp;z&nbsp;radością</b>.
                                <br/>
                                Dlatego też stworzyliśmy nowe receptury karm, które właśnie wchodzą do&nbsp;sprzedaży.
                            </p>
                        </Text>
                        <Text>
                            <p>
                                <Image image={tula2} alt=""/>
                            </p>
                        </Text>
                    </div>
                    <div className="o-tuli__inner-2">
                        <Text>
                            <p>
                                <b>Poznajcie markę TULA</b> – nową mięsną markę karm, które sprawdzają&nbsp;się w&nbsp;codziennym żywieniu kotów i&nbsp;psów wszystkich&nbsp;ras.
                                To&nbsp;także <b>pełna pozytywnych emocji marka</b>, która promuje przyjacielską więź między opiekunem a&nbsp;jego domowym&nbsp;przyjacielem.
                                <br/>
                                <br/>
                                <div className="text-big">
                                    TULA – TROSKLIWI Z&nbsp;NATURY
                                </div>
                            </p>
                        </Text>
                        <Text>
                            <p>
                                <Image image={tula1} alt=""/>
                            </p>
                        </Text>
                    </div>
                </div>
            </div>
        </Screen>
    </>);
};

export default About;
