import React, {useState, useRef} from "react";
import Header from "../sections/Header";
import Image from "../sections/Image";
import Button from "../sections/Button";
import Screen from "../sections/Screen";

import ImageFile from "../images/03.png";
import Text from "../sections/Text";

import {useScrollSection,} from 'react-scroll-section';
import Wholesaler from "./Wholesaler";
import Shops from "./Shops";

const WhereFindUs = () => {

    const [wholesalerActive, setWholesalerActive] = useState(false);
    const [shopsActive, setShopsActive] = useState(false);
    const WholesalerRef = useRef(false);
    const ShopsRef = useRef(false);

    const sections = {
        wholesaler: useScrollSection('Wholesaler'),
        shops: useScrollSection('Shops'),
    };

    const handleWholesalerButtonClick = (e) => {
        e.preventDefault();
        setWholesalerActive(!wholesalerActive);
        if(!wholesalerActive) {
            setTimeout(() => {
                const y = WholesalerRef.current.getBoundingClientRect().top + window.scrollY + 250;
                window.scroll({
                    top: y,
                    behavior: 'smooth'
                });
            }, 100);
        }
        // sections.wholesaler.onClick();
    }

    const handleShopsButtonClick = (e) => {
        e.preventDefault();
        setShopsActive(!shopsActive);
        if(!shopsActive) {
            setTimeout(() => {
                const y = ShopsRef.current.getBoundingClientRect().top + window.scrollY + 250;
                window.scroll({
                    top: y,
                    behavior: 'smooth'
                });
            }, 100);
        }
        // sections.shops.onClick();
    }

    return (<>
        <Screen>
            <Header headerSize="2" title="Znajdź nas"/>
            <Image image={ImageFile} alt="Znajdź nas" expand={false}/>
            <Text>
                <p className="text-big">
                    Ze sklepowej na domową półkę,<br/>
                    czyli gdzie możesz dostać karmę&nbsp;TULA.
                </p>
                <p>
                    Zarówno suchą, jak i&nbsp;mokrą karmę&nbsp;TULA możesz znaleźć w&nbsp;hurtowniach Grupy&nbsp;Eurocash oraz w&nbsp;naszej sieci sklepów&nbsp;spożywczych.
                </p>
            </Text>

            <Button onClick={handleWholesalerButtonClick} label="Hurtownie"></Button>
            <div ref={WholesalerRef}>
                <Wholesaler active={wholesalerActive}/>
            </div>
            <Button onClick={handleShopsButtonClick} label="Sklepy detaliczne"></Button>
            <div ref={ShopsRef}>
                <Shops active={shopsActive}/>
            </div>

        </Screen>
    </>);
};

export default WhereFindUs;
