import React from "react";
import Menu from "./sections/Menu";
import "./App.scss";

import {ScrollingProvider, Section,} from 'react-scroll-section';

import Main from "./pages/Main";
import About from "./pages/About";
import Food from "./pages/Food";
import DogsProducts from "./pages/DogsProducts";
import CatsProducts from "./pages/CatsProducts";
import WhereFindUs from "./pages/WhereFindUs";
import Shops from "./pages/Shops";
import Screen from "./sections/Screen";
import Wholesaler from "./pages/Wholesaler";

const App = () => {

    return (
        <ScrollingProvider offset={window.innerWidth < 1024 ? -20 : 0}>
            <Menu/>
            <div id="Sections" className="sections">
                <Section id="MainSection">
                    <Main/>
                </Section>
                <Section id="AboutSection">
                    <About/>
                </Section>
                <Section id="FoodSection">
                    <Food/>
                </Section>
                <Section id="DogsProductsSection">
                    <DogsProducts/>
                </Section>
                <Section id="CatsProductsSection">
                    <CatsProducts/>
                </Section>
                <Section id="WhereSection">
                    <WhereFindUs/>
                </Section>
                {/*<Screen>*/}
                {/*    <Section id="Wholesaler">*/}
                {/*        <Wholesaler/>*/}
                {/*    </Section>*/}
                {/*    <Section id="Shops">*/}
                {/*        <Shops/>*/}
                {/*    </Section>*/}
                {/*</Screen>*/}
            </div>
        </ScrollingProvider>
    );
}

export default App;
