import React from "react";
import Text from "../sections/Text";
import ImgSklep1 from "../images/sklepy/sklep1.png";
import ImgSklep2 from "../images/sklepy/hurt2.png";

const Wholesaler = ({active = false}) => {
    return (<>
        <div id="hurtownie" style={{display: active ? "block" : "none"}}>
            {/*<Text>*/}
                {/*<br/>*/}
                {/*<p className="text-big">*/}
                {/*    Nasze karmy znajdziesz na&nbsp;eurocash.pl oraz w&nbsp;hurtowniach:*/}
                {/*    <br/>*/}
                {/*    <br/>*/}
                {/*</p>*/}
            {/*</Text>*/}
            <p className="shops">
                <div>
                    <img src={ImgSklep1} alt="" width="200" height="200"/>
                </div>
                <div>
                    <img src={ImgSklep2} alt="" width="200" height="200"/>
                </div>
            </p>
        </div>
    </>);
};

export default Wholesaler;
