import React, {useState} from "react";
import { Carousel } from 'react-responsive-carousel';

import "react-responsive-carousel/lib/styles/carousel.min.css";
import "./Tabs.scss";



const Tabs = (props) => {

  const [currentTab, setCurrentTab] = useState(0);

  const tabListElementClick = (key) => {
    setCurrentTab(key);
    props.setActiveTab(key)
  }

  const createTabsList = () => {
    return props.tabs.map((tab, key) => {
      return (<li key={key} className={props.activeTab === key ? "active": ""} onClick={() => {tabListElementClick(key)}}>{tab}</li>);
    });
  }

  return(
    <div className="tabs">
      <ul className="tabs__list">
        {createTabsList()}
      </ul>
      <div className="tabs__screens">
        <Carousel selectedItem={currentTab} swipeable={false} showArrows={false} dots={false} showStatus={false} onChange={(currentElement) => { tabListElementClick(currentElement); }}>
          {props.children}
        </Carousel>
      </div>
    </div>
  );
};

export default Tabs;
